/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {getMethod, execute} from './api';
import {generateKey} from '../utils/GeneralUtils';
import {cacheClearFunctions, modificationExceptions} from './responseCacheInvalidation';

// Store all the responses in this object
const responses = {};
// Expire a response after 15 minutes by default
const defaultCacheExpiryInMinutes = 15;

/**
 * Function to determine if the API call just made modifies resources
 * in the PCE. Typically these calls are non idempotent.
 *
 * @param {string}   resource - Name of the resource (workloads, rule_sets, etc.).
 * @param {string}   method - Name of the method (update, create, etc).
 * @return {boolean} Boolean whether the API call modified any PCE resource.
 */
const isModificationCall = (resource, method) => {
  const notGet = getMethod(resource, method).http_method !== 'GET';
  const isException = modificationExceptions.some(
    ([exceptionResource, exceptionMethod]) => exceptionResource === resource && exceptionMethod === method,
  );

  return notGet && !isException;
};

/**
 * Function to get expiration timestamp for the cached Response
 *
 * @param {number}  cacheExpiryInMinutes - Minutes in which cache should be expired.
 * @return {number} timestamp after which the cache is invalid.
 */
const getExpiration = (cacheExpiryInMinutes = defaultCacheExpiryInMinutes) => {
  const expiration = new Date();

  // setMinutes returns Milliseconds timestamp
  return expiration.setMinutes(expiration.getMinutes() + cacheExpiryInMinutes);
};

/**
 * Function to store API responses
 *
 * @param {Array.<string|Object>} executeArgs - Array contains resource, method and options.
 * @param {string}                optionsKey  - Stringified options Object of the request.
 * @param {Object}                response    - Object with Response instance returned by Fetch.
 */
const storeResponse = (executeArgs, optionsKey, response) => {
  const [resource, method, {cacheExpiryInMinutes}] = executeArgs;

  if (!response?.response?.ok) {
    // Don't cache Response if not ok (that is status not in 200s)
    return;
  }

  responses[resource] ||= {};
  responses[resource][method] ||= {};
  responses[resource][method][optionsKey] = {
    response,
    expiration: getExpiration(cacheExpiryInMinutes),
  };
};

/**
 * Function to return the cached response of a request
 *
 * @param {string}              resource   - Name of the resource to look up.
 * @param {string}              method     - Name of the method to look up.
 * @param {string}              optionsKey - Stringified options Object of the request.
 * @return {(Object|undefined)} Cached result Object with Response.
 */
const getCachedResponse = (resource, method, optionsKey) => {
  responses[resource] ||= {};
  responses[resource][method] ||= {};

  const cachedResponse = responses[resource][method][optionsKey];

  if (!cachedResponse) {
    return;
  }

  if (cachedResponse.expiration < Date.now()) {
    // Remove expired response from cache
    delete responses[resource][method][optionsKey];

    return;
  }

  return cachedResponse.response;
};

/**
 * Function to look up the custom cache clear function
 * And call it (if exists) to determine whether to clear the responses
 *
 * @param {string}  resource       - Name of the resource which was executed.
 * @param {string}  method         - Name of the method which was executed.
 * @param {Object}  options        - Options object execute() was called with.
 * @param {boolean} isModification - Whether the method is a PCE modifying method.
 * @param {string}  responseKey    - Name of the cached resource.
 */
const customClearResponse = (resource, method, options, isModification, responseKey) => {
  for (const methodName of Object.keys(responses[responseKey])) {
    let deleteMethod = false;

    if (cacheClearFunctions[responseKey][methodName]) {
      deleteMethod = cacheClearFunctions[responseKey][methodName](resource, method, options);
    } else {
      deleteMethod = isModification;
    }

    if (deleteMethod) {
      delete responses[responseKey][methodName];
    }
  }
};

/**
 * Function to clear Responses based on a specific resource's specific methods
 *
 * @param {string} resource - Name of the resource which was executed.
 * @param {string} method   - Name of the method which was executed.
 * @param {Object} options  - Options object execute() was called with.
 */
export const clearCachedResponses = (resource, method, options) => {
  const responseKeys = Object.keys(responses);

  if (responseKeys.length === 0) {
    return;
  }

  const isModification = isModificationCall(resource, method);

  for (const responseKey of responseKeys) {
    if (cacheClearFunctions[responseKey] && responses[responseKey]) {
      customClearResponse(resource, method, options, isModification, responseKey);
    } else if (isModification) {
      delete responses[responseKey];
    }
  }
};

/**
 * Function to find a valid cached result with Response (if exists) and
 * then call the execute method in lib/api with the cached response.
 *
 * @param {string}   resource        - Name of the resource which will be executed.
 * @param {string}   method          - Name of the method which will be executed.
 * @param {Object}   options         - Options object execute() was called with.
 * @param {boolean}  preventDispatch - Whether to prevent dispatch.
 * @return {Promise} Result Promise returned from execute in api.js.
 */
export const cachedExecute = async (resource, method, options = {}, preventDispatch = false) => {
  const {noCache, ...execOptions} = options;
  const executeArgs = [resource, method, execOptions, preventDispatch];
  const optionsKey = generateKey(execOptions);
  let cachedResponse;

  if (!noCache) {
    // If the noCache flag is present, do not use cache
    cachedResponse = getCachedResponse(resource, method, optionsKey);
    executeArgs.push(cachedResponse);
  }

  const response = await execute(...executeArgs);

  if (!cachedResponse) {
    // Store the response if not already stored
    // Requests with noCache flag are cached too as the subsequent
    // request may not have noCache flag (this is why noCache flag
    // is not party of the optionsKey)
    storeResponse(executeArgs, optionsKey, response);
  }

  return response;
};
